var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"post-form py-2 frame-user-activity-post w-100-break",attrs:{"fluid":""}},[_c('b-row',{staticClass:"mt-2 pb-2"},[_c('b-col',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.postActivity($event)}}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-right pr-2 pl-2 mr-2"},[_c('ScanTraderAvatar',{attrs:{"width":"40","height":"40","src":_vm.accountAvatar || _vm.user.my_info.avatar}})],1),_vm._v(" "),_c('div',{staticClass:"flex-fill pr-2"},[_c('CommonFroalaEditor',{ref:"commonFroalaEditor",staticClass:"st_none-none-big st-md_none-none-bigger",attrs:{"placeholderText":(_vm.accountNick || _vm.user.my_info.name.nick) +
                  '，最近有什麼有趣觀察嗎？'},nativeOn:{"focus":function($event){return _vm.onFocusTextarea($event)},"blur":function($event){return _vm.onBlurTextarea($event)}},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_vm._v(" "),_c('div',{staticClass:"text-right"},[(!_vm.form.image)?_c('span',{staticClass:"btn-image pointer",on:{"click":function($event){$event.preventDefault();return _vm.onInsertImage($event)}}},[_c('i',{staticClass:"fa fa-image"})]):_vm._e(),_vm._v(" "),_c('b-form-file',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"imageUpload",staticClass:"d-none",attrs:{"accept":"image/*","plain":""},on:{"change":_vm.onFileChange},model:{value:(_vm.form.imageFile),callback:function ($$v) {_vm.$set(_vm.form, "imageFile", $$v)},expression:"form.imageFile"}})],1)]),_vm._v(" "),(_vm.form.image)?_c('div',{staticClass:"mt-2 pt-2"},[_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticClass:"image-preview pointer",on:{"click":function($event){$event.preventDefault();return _vm.expandImage($event)}}},[_c('img',{staticClass:"preview",attrs:{"src":_vm.form.image,"alt":"預覽"}}),_vm._v(" "),_c('span',{staticClass:"btn-clear d-inline-block",on:{"click":function($event){$event.preventDefault();return _vm.removeImage($event)}}},[_c('i',{staticClass:"material-icons"},[_vm._v(" clear ")])]),_vm._v(" "),_c('span',{staticClass:"btn-expand pointer"},[_c('i',{staticClass:"material-icons"},[_vm._v(" zoom_out_map ")])])])]):_vm._e(),_vm._v(" "),(
            _vm.isTextareaFocus ||
              _vm.form.content.length > 0 ||
              _vm.form.imageFile ||
              _vm.paySetting
          )?_c('div',{staticClass:"mt-2 d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-sm-inline pl-2"},[(_vm.channelsOption.length > 0)?_c('div',[_c('md-checkbox',{staticClass:"checkbox-limit st_color_main_light",attrs:{"disabled":_vm.lockChannels},model:{value:(_vm.paySetting),callback:function ($$v) {_vm.paySetting=$$v},expression:"paySetting"}},[_vm._v("\n                限訂閱者觀看\n              ")])],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"d-sm-inline-block text-right"},[_c('b-button',{staticClass:"btn-submit st_background_main_light",attrs:{"type":"submit","disabled":_vm.form.content.length === 0 ||
                  (_vm.paySetting && _vm.form.channels.length === 0) ||
                  _vm.submitting}},[_vm._v("\n              發佈\n            ")])],1)]):_vm._e()])],1),_vm._v(" "),_c('b-col',{staticClass:"flex-fill px-0"},[(_vm.paySetting && _vm.channelsOption.length > 0)?_c('div',{staticClass:"border-top mt-2 pt-3 pl-4 pr-3"},[_c('div',[_c('h5',{staticClass:"d-inline-block text-note-title st_color_gray"},[_vm._v("\n            誰可以看到這個訂閱內容？\n            "),_c('span',{staticClass:"text-note-brief st_color_gray_dark"},[_vm._v("\n              (將即時推播至訂閱戶的個人LINE)\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"mt-3 d-inline-block post-form-service"},[_c('md-checkbox',{staticClass:"checkbox-orange st_color_main_light",attrs:{"indeterminate":_vm.channelsSelected,"value":true,"uncheckedValue":false},on:{"change":_vm.channelsClickSelectAll},model:{value:(_vm.channelsSelectAll),callback:function ($$v) {_vm.channelsSelectAll=$$v},expression:"channelsSelectAll"}},[_vm._v("\n            全選\n          ")]),_vm._v(" "),_c('b-row',{staticClass:"frame-checkbox mt-1"},_vm._l((_vm.channelsOption),function(x,i){return _c('b-col',{key:i,attrs:{"cols":"6","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('md-checkbox',{staticClass:"checkbox-orange st_color_main_light",attrs:{"value":x.value,"name":"channels"},model:{value:(_vm.form.channels),callback:function ($$v) {_vm.$set(_vm.form, "channels", $$v)},expression:"form.channels"}},[_vm._v("\n                "+_vm._s(x.text)+"\n              ")])],1)}),1)],1)]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }