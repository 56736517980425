<template>
  <b-container
    class="post-form py-2 frame-user-activity-post w-100-break"
    fluid>
    <b-row class="mt-2 pb-2">
      <b-col>
        <b-form @submit.prevent="postActivity">
          <div class="d-flex">
            <div class="text-right pr-2 pl-2 mr-2">
              <ScanTraderAvatar
                width="40"
                height="40"
                :src="accountAvatar || user.my_info.avatar" />
            </div>
            <div class="flex-fill pr-2">
              <CommonFroalaEditor
                class="st_none-none-big st-md_none-none-bigger"
                :placeholderText="
                  (accountNick || user.my_info.name.nick) +
                    '，最近有什麼有趣觀察嗎？'
                "
                v-model="form.content"
                ref="commonFroalaEditor"
                @focus.native="onFocusTextarea"
                @blur.native="onBlurTextarea" />
            </div>
            <div class="text-right">
              <span
                @click.prevent="onInsertImage"
                v-if="!form.image"
                class="btn-image pointer">
                <i class="fa fa-image" />
              </span>
              <b-form-file
                v-model="form.imageFile"
                ref="imageUpload"
                @change="onFileChange"
                v-show="false"
                accept="image/*"
                plain
                class="d-none" />
            </div>
          </div>
          <div v-if="form.image" class="mt-2 pt-2">
            <div
              class="image-preview pointer"
              v-viewer
              @click.prevent="expandImage">
              <img :src="form.image" alt="預覽" class="preview">
              <span
                class="btn-clear d-inline-block"
                @click.prevent="removeImage">
                <i class="material-icons"> clear </i>
              </span>
              <span class="btn-expand pointer">
                <i class="material-icons"> zoom_out_map </i>
              </span>
            </div>
          </div>
          <div
            class="mt-2 d-flex justify-content-between align-items-center"
            v-if="
              isTextareaFocus ||
                form.content.length > 0 ||
                form.imageFile ||
                paySetting
            ">
            <div class="d-sm-inline pl-2">
              <div v-if="channelsOption.length > 0">
                <md-checkbox
                  class="checkbox-limit st_color_main_light"
                  v-model="paySetting"
                  :disabled="lockChannels">
                  限訂閱者觀看
                </md-checkbox>
              </div>
            </div>
            <div class="d-sm-inline-block text-right">
              <b-button
                type="submit"
                class="btn-submit st_background_main_light"
                :disabled="
                  form.content.length === 0 ||
                    (paySetting && form.channels.length === 0) ||
                    submitting
                ">
                發佈
              </b-button>
            </div>
          </div>
        </b-form>
      </b-col>
      <b-col class="flex-fill px-0">
        <div
          v-if="paySetting && channelsOption.length > 0"
          class="border-top mt-2 pt-3 pl-4 pr-3">
          <div>
            <h5 class="d-inline-block text-note-title st_color_gray">
              誰可以看到這個訂閱內容？
              <span class="text-note-brief st_color_gray_dark">
                (將即時推播至訂閱戶的個人LINE)
              </span>
            </h5>
          </div>
          <div class="mt-3 d-inline-block post-form-service">
            <md-checkbox
              class="checkbox-orange st_color_main_light"
              v-model="channelsSelectAll"
              :indeterminate="channelsSelected"
              @change="channelsClickSelectAll"
              :value="true"
              :uncheckedValue="false">
              全選
            </md-checkbox>
            <b-row class="frame-checkbox mt-1">
              <b-col
                cols="6"
                sm="12"
                md="6"
                lg="6"
                xl="6"
                v-for="(x, i) of channelsOption"
                :key="i">
                <md-checkbox
                  class="checkbox-orange st_color_main_light"
                  v-model="form.channels"
                  :value="x.value"
                  name="channels">
                  {{ x.text }}
                </md-checkbox>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import CommonFroalaEditor from '~/components/forms/CommonFroalaEditor-v2'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      CommonFroalaEditor,
    },
    props: {
      channelsOptions: {
        type:    Array,
        default: null,
      },
      account: {
        type:    String,
        default: null,
      },
      accountAvatar: {
        type:    String,
        default: null,
      },
      accountNick: {
        type:    String,
        default: null,
      },
      lockChannels: {
        type:    Boolean,
        default: false,
      },
    },
    data () {
      const randomId = this.$utils.generateId(12)
      const toolbarButtons = [
        'h1',
        'h2',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'color',
        'clearFormatting',
        '-',
        'align',
        'subscript',
        'superscript',
        'quote',
        'formatOL',
        'formatUL',
        'indent',
        'outdent',
        '-',
        'insertLink',
        'insertImage',
        'insertVideo',
        'insertFile',
        'embedly',
        'insertHR',
        'insertTable',
        'undo',
        'redo',
      ]
      return {
        isTextareaFocus:   false,
        paySetting:        false,
        submitting:        false,
        channelsSelectAll: false,
        channelsSelected:  false,
        config:            {
          key:            this.$store.getters.siteSetting.froalaEditorV2Key,
          language:       'zh_tw',
          pluginsEnabled: [
            'align',
            'charCounter',
            'colors',
            'draggable',
            'embedly',
            'entities',
            'file',
            'image',
            'lineBreaker',
            'link',
            'lists',
            'paragraphFormat',
            'quickInsert',
            'quote',
            'table',
            'url',
            'video',
            'wordPaste',
          ],
          requestWithCredentials: true,
          requestHeaders:         {
            Authorization:
              this.$axios.defaults.headers.common.Authorization || '',
          },
          editorClass:       `froala-editor froala-editor__articleEdit froala-editor-${randomId}`,
          iconsTemplate:     'font_awesome_5',
          toolbarInline:     true,
          charCounterCount:  false,
          toolbarButtons:    toolbarButtons,
          toolbarButtonsMD:  toolbarButtons,
          toolbarButtonsSM:  toolbarButtons,
          toolbarButtonsXS:  toolbarButtons,
          tabSpaces:         4,
          placeholderText:   this.placeholderText,
          heightMin:         500,
          htmlAllowComments: false,
          htmlAllowedTags:   [
            'a',
            'b',
            'blockquote',
            'br',
            'div',
            'embed',
            'h1',
            'h2',
            'hr',
            'i',
            'iframe',
            'img',
            'li',
            'ol',
            'p',
            'small',
            'source',
            'span',
            'strike',
            'strong',
            'sub',
            'sup',
            'table',
            'tbody',
            'td',
            'tfoot',
            'th',
            'thead',
            'tr',
            'u',
            'ul',
            'markettag',
          ],
          htmlAllowedStyleProps: [
            'color',
            'width',
            'height',
            'text-align',
            'vertical-align',
          ],
          htmlAllowedAttrs: [
            'accept',
            'accept-charset',
            'accesskey',
            'action',
            'align',
            'allowfullscreen',
            'allowtransparency',
            'alt',
            'autocomplete',
            'autoplay',
            'border',
            'charset',
            'cellpadding',
            'cellspacing',
            'checked',
            'cite',
            'class',
            'color',
            'cols',
            'colspan',
            'content',
            'controls',
            'coords',
            'data',
            'data-.*',
            'datetime',
            'default',
            'defer',
            'dir',
            'dirname',
            'disabled',
            'download',
            'draggable',
            'dropzone',
            'enctype',
            'for',
            'form',
            'formaction',
            'frameborder',
            'headers',
            'height',
            'hidden',
            'high',
            'href',
            'hreflang',
            'http-equiv',
            'icon',
            'id',
            'ismap',
            'itemprop',
            'keytype',
            'kind',
            'label',
            'lang',
            'language',
            'list',
            'loop',
            'low',
            'max',
            'maxlength',
            'media',
            'method',
            'min',
            'mozallowfullscreen',
            'multiple',
            'muted',
            'name',
            'novalidate',
            'open',
            'optimum',
            'pattern',
            'ping',
            'placeholder',
            'playsinline',
            'poster',
            'preload',
            'pubdate',
            'radiogroup',
            'readonly',
            'rel',
            'required',
            'reversed',
            'rows',
            'rowspan',
            'sandbox',
            'scope',
            'scoped',
            'scrolling',
            'seamless',
            'selected',
            'shape',
            'size',
            'sizes',
            'span',
            'src',
            'srcdoc',
            'srclang',
            'srcset',
            'start',
            'step',
            'summary',
            'spellcheck',
            'style',
            'tabindex',
            'target',
            'title',
            'type',
            'translate',
            'usemap',
            'value',
            'valign',
            'webkitallowfullscreen',
            'width',
            'wrap',
          ],
          inlineStyles:        {},
          inlineClasses:       {},
          lineHeights:         { Default: '' },
          colorsBackground:    [],
          colorsHEXInput:      false,
          colorsText:          ['#00bc69', '#ff4b4b', 'REMOVE'],
          fontFamily:          {},
          fontSize:            [],
          imageUploadMethod:   'POST',
          imageUploadURL:      `${this.$store.getters.siteSetting.apiUrlSocial}/upload?linkURL=true`,
          imageMaxSize:        5 * 1024 * 1024,
          imageAllowedTypes:   ['jpeg', 'jpg', 'png', 'gif', 'webp', 'svg'],
          imageInsertButtons:  ['imageBack', '|', 'imageUpload', 'imageByURL'],
          imageMultipleStyles: false,
          imageEditButtons:    [
            'imageReplace',
            'imageAlign',
            'imageCaption',
            'imageRemove',
            '-',
            'imageLink',
            'linkOpen',
            'linkEdit',
            'linkRemove',
            '|',
            'imageDisplay',
            'imageAlt',
            'imageSize',
            'image100',
          ],
          fileUpload:         true,
          fileUploadMethod:   'POST',
          fileUploadURL:      `${this.$store.getters.siteSetting.apiUrlSocial}/upload?linkURL=true`,
          fileMaxSize:        10 * 1024 * 1024,
          videoUpload:        false,
          linkMultipleStyles: false,
          linkAlwaysBlank:    true,
          linkInsertButtons:  ['linkBack'],
          linkList:           [],
          linkEditButtons:    ['linkOpen', 'linkEdit', 'linkRemove'],
          listAdvancedTypes:  false,
          paragraphFormat:    {
            N:  'Normal',
            H1: 'Heading 1',
            H2: 'Heading 2',
          },
          paragraphFormatSelection: true,
          pasteAllowedStyleProps:   ['font-size'],
          pasteDeniedAttrs:         ['id', 'class'],
          quickInsertButtons:       [
            'image',
            'video',
            'file',
            'embedly',
            'table',
            'ul',
            'ol',
            'hr',
          ],
          tableEditButtons: [
            'tableHeader',
            'tableRemove',
            '|',
            'tableRows',
            'tableColumns',
            '-',
            'tableCells',
            'tableCellVerticalAlign',
            'tableCellHorizontalAlign',
          ],
          tableMultipleStyles:   false,
          wordAllowedStyleProps: [
            'color',
            'width',
            'text-align',
            'vertical-align',
            'height',
            'text-decoration',
            'font-style',
          ],
          wordPasteModal:          false,
          wordPasteKeepFormatting: true,
          lineBreakerTags:         [
            '.froalaEditor__table-wrapper',
            'hr',
            'form',
            'dl',
            'span.fr-video',
            '.fr-embedly',
            '.fr-img-caption',
          ],
          events: {
            'froalaEditor.initialized': (e, editor) => {
              editor.$el
                .find('markettag')
                .attr({
                  class:                  'mention fr-deletable',
                  contenteditable:        'false',
                  'data-denotation-char': '$',
                })
                .text('$')
              const config = this.$tribute.collection.find(item => item)
              config.trigger = '$'
              config.lookup = 'value'
              config.selectTemplate = item => {
                const newElem = document.createElement('markettag')
                newElem.className = 'mention fr-deletable'
                newElem.innerHTML = '$'
                newElem.setAttribute('contenteditable', 'false')
                newElem.setAttribute('data-denotation-char', '$')
                newElem.setAttribute(
                  'data-exchangeName',
                  item.original.exchangeName,
                )
                newElem.setAttribute('data-id', item.original.id)
                newElem.setAttribute(
                  'data-namePrimary',
                  item.original.namePrimary,
                )
                newElem.setAttribute(
                  'data-tickerSymbol',
                  item.original.tickerSymbol,
                )
                newElem.setAttribute('data-value', item.original.value)
                return newElem.outerHTML
              }
              config.menuItemLimit = 100
              config.values = this.renderMarketTag
              config.noMatchTemplate = () => '<span style:"visibility: hidden;"></span>'
              this.$tribute.attach(editor.$el)
              editor.events.on(
                'keydown',
                e => {
                  if (
                    e.which === $.FroalaEditor.KEYCODE.ENTER
                    && this.$tribute.isActive
                  ) {
                    return false
                  }
                  return true
                },
                true,
              )
              editor.events.on('destroy', () => {
                this.$tribute.detach(editor.$el)
                this.$tribute.menu = null
              })

              this.wrapTable(editor)
              // make sure old data can be wrapped by this.wrapTable and save to database
              if (editor.$oel.froalaEditor('html.get') !== this.inputVal) this.inputVal = editor.$oel.froalaEditor('html.get')
            },
            'froalaEditor.image.error': (e, editor, error, response) => {
              console.log(error, response)
            },
            'froalaEditor.paste.beforeCleanup': (e, editor, clipboard_html) => this.covertPasteHtml(clipboard_html),
            'froalaEditor.paste.after':         (e, editor) => {
              this.wrapTable(editor)
              this.reformatMarketTag(editor)
            },
            'froalaEditor.table.inserted': (e, editor) => {
              this.wrapTable(editor)
            },
          },
        },
        form: {
          content:   '',
          image:     '',
          imageFile: null,
          channels:  [],
        },
        name:          'test',
        onblurTimeout: null,
      }
    },
    computed: {
      ...mapGetters(['user']),
      channelsOption () {
        return this.channelsOptions && this.channelsOptions.length > 0
          ? this.channelsOptions.map(channel => ({
            value: channel.identity,
            text:  channel.title,
          }))
          : []
      },
    },
    watch: {
      'form.channels': 'checkChannelsSelectAll',
      'form.content':  'checkContent',
      paySetting:      'paySettingChange',
    },
    methods: {
      ...mapActions('api/upload', ['uploadFile']),
      ...mapActions('api/article', ['createArticle']),
      generateSummary (html) {
        return this.$utils.filterAllHtmlTags(html, this.inService ? 50 : 150)
      },
      generateMarketTag (html) {
        const marketTag = []
        const div = document.createElement('div')
        div.innerHTML = html.trim()
        div.querySelectorAll('markettag').forEach(node => {
          if (marketTag.length < 10 && !marketTag.includes(node.dataset.id)) {
            marketTag.push(node.dataset.id)
          }
        })
        return marketTag
      },
      getTextreaMinHeight () {
        const lines = this.form.content.split(/\r*\n/).length
        let height = (lines - 1) * 24 + 36
        if (height > 200) {
          height = 200
        }
        return `${height}px`
      },
      onBlurTextarea () {
        this.onblurTimeout = setTimeout(this.onBlurTextareaDo, 500)
      },
      onBlurTextareaDo () {
        this.isTextareaFocus = false
        clearTimeout(this.onblurTimeout)
      },
      onFocusTextarea () {
        // alert('onFocusTextarea')
        this.isTextareaFocus = true
        clearTimeout(this.onblurTimeout)
      },
      onInsertImage () {
        this.$refs.imageUpload.$el.click()
      },
      onFileChange (e) {
        const files = e.target.files || e.dataTransfer.files
        if (!files.length) {
          return
        }
        const maxFileSizeMb = 5
        const fileSize = files[0].size / 1024 / 1024

        if (fileSize > maxFileSizeMb) {
          this.$toast.show(`檔案大小不得超過 ${maxFileSizeMb}Mb`).goAway(3000)
          this.removeImage()
          return
        }

        if (files[0].type.match(/image.*/gi)) {
          this.createImage(files[0])
        } else {
          this.removeImage()
        }
      },
      createImage (file) {
        const reader = new FileReader()
        const vm = this

        reader.onload = e => {
          vm.form.image = e.target.result
        }
        reader.readAsDataURL(file)
      },
      removeImage () {
        this.$refs.imageUpload.$el.value = ''
        this.form.image = ''
        this.form.imageFile = null
      },
      paySettingChange () {
        this.form.channels = this.paySetting
          ? this.channelsOption.map(channel => channel.value)
          : []
      },
      checkChannelsSelectAll (newVal) {
        if (newVal.length === 0) {
          this.channelsSelected = false
          this.channelsSelectAll = false
        } else if (newVal.length === this.channelsOption.length) {
          this.channelsSelected = false
          this.channelsSelectAll = true
        } else {
          this.channelsSelected = true
          this.channelsSelectAll = false
        }
      },
      checkContent () {
      // const content = this.form.content.slice(-1)
      },
      channelsClickSelectAll (checked) {
        this.form.channels = checked
          ? this.channelsOption.map(channel => channel.value)
          : []
      },
      clearForm () {
        this.form.content = ''
        this.form.image = ''
        this.form.imageFile = null
        this.$refs.imageUpload.$el.value = ''
        this.submitting = false
      },
      formatFormData (cover) {
        this.content = this.form.content.trim()
        return {
          kind:       'news',
          content:    this.content,
          summary:    this.generateSummary(this.content),
          marketTags: this.generateMarketTag(this.content),
          cover:      cover || '',
          channels:   this.form.channels,
          account:    this.account || this.user.my_info.identity,
        }
      },
      async postActivity () {
        this.submitting = true
        let cover = null
        if (this.form.imageFile) {
          cover = await this.uploadFile(this.form.imageFile)
        }
        const article = this.formatFormData(cover)
        await this.createArticle(article)

        this.clearForm()
        this.$emit('update')
      },
      expandImage (e) {
        e.target.parentNode.parentNode.querySelector('.preview').click()
      },
    },
    created () {
      if (this.lockChannels) {
        this.paySetting = true
      }
    },
  }
</script>

<style lang="scss" scoped>
.post-form {
  .textarea {
    border: none;
    min-height: 36px;
    height: 36px;
    resize: none;
    outline: none;
    box-shadow: none;
  }

  .textarea::-webkit-input-placeholder {
    color: $st_color_gray_dark;
  }

  .textarea:focus::-webkit-input-placeholder {
    color: $st_color_gray_light;
  }

  .btn-submit {
    padding: 5px 30px;
    border-radius: 2px;
    color: white;
    font-size: $st_font-size-base;
    font-weight: $st_font-weight-medium;
    letter-spacing: $st_font_letter-spacing;
    text-align: center;
  }

  .pointer {
    cursor: pointer;
  }

  .btn-image {
    font-size: 30px;
    opacity: 0.8;
  }

  .text-note-title {
    font-size: $st_font-size-base;
    font-weight: $st_font-weight-medium;
    letter-spacing: $st_font_letter-spacing;
  }

  .text-note-brief {
    font-size: $st_font-size-small;
    font-weight: $st_font-weight-light;
    letter-spacing: 0.7px;
  }

  .checkbox-orange {
    font-size: $st_font-size-big;
    letter-spacing: $st_font_letter-spacing;
  }

  .checkbox-limit {
    font-size: $st_font-size-base;
    letter-spacing: $st_font_letter-spacing;
  }

  .image-preview {
    display: inline-block;
    position: relative;
    width: 200px;
    height: 200px;
    line-height: 200px;
    overflow: hidden;
    margin-left: 76px;
    text-align: center;
    border-color: rgba($st_color_gray_light, 0.35);
    border-style: dashed;
    border-width: 2px;
    background-color: white;
    z-index: 2;

    img {
      position: relative;
      max-width: 200px;
      max-height: 200px;
      vertical-align: middle;
      transition: 0.4s;
      z-index: -2;
    }

    .btn-clear {
      cursor: pointer;
      border-radius: 50%;
      color: white;
      width: 26px;
      height: 26px;
      padding-top: 2px;
      text-align: center;
      background-color: rgba(43, 44, 55, 0.5);
      right: 5px;
      top: 5px;
      position: absolute;
      z-index: 1;
      line-height: 0;

      i {
        font-size: $st_font-size-bigger;
      }
    }

    .btn-expand {
      border-radius: 50%;
      position: absolute;
      width: 26px;
      height: 26px;
      padding-top: 1px;
      text-align: center;
      left: 5px;
      top: 5px;
      color: white;
      background-color: rgba(43, 44, 55, 0.5);
      transition-property: top, left, transform, width, height;
      transition-duration: 0.4s;
      line-height: 0;

      i {
        transition-property: font-size;
        transition-duration: 0.4s;
      }
    }

    &:after {
      transition: 0.4s;
      content: '';
    }

    &:hover {
      &::after {
        content: '';
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        z-index: -1;
        height: 100%;
        bottom: 0;
        left: 0;
        right: 0;
      }

      .btn-expand {
        top: 50%;
        left: 50%;
        transform: translate(-60%, -60%);
        background: transparent;

        i {
          font-size: 36px;
        }
      }
    }
  }

  .checkbox-label {
    font-size: $st_font-size-base;
  }
}
</style>
