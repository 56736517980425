<template>
  <b-container class="pb-4 pb-sm-5 u-page-inner">
    <followExpertButNotLoginModal />
    <b-row>
      <b-col cols="12" md="8">
        <PageBar :curIndex="0" class="mb-3 mt-md-3 activity-post-wrap" />
        <div class="activity-post-wrap">
          <div class="mb-3 flex-fill" v-if="userCanPublish">
            <b-container class="frame-post mb-3 px-0">
              <div class="form-for-focus">
                <b-row noGutters>
                  <b-col class="mb-2" cols="12">
                    <div class="title-frame px-1 pl-3 py-2 st_color_gray_light">
                      <i class="fa fa-bullhorn" />
                      <span class="align-middle"> 發佈動態 </span>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <client-only>
                      <ActivityFroalaEditor
                        :account="user.user_info.identity"
                        :accountAvatar="
                          user.user_info.avatar || require('~/static/images/png_icon/mdpi/icon_profile_default.png')
                        "
                        :accountNick="user.user_info.name.nick"
                        :channelsOptions="userCanSetChannels ? channels : []"
                        :lockChannels="userCanOnlySetChannels"
                        @update="refreshActivity()"
                        v-model="content"
                        class="bg-white pl-0 w-100-break" />
                    </client-only>
                  </b-col>
                </b-row>
              </div>
            </b-container>
          </div>
          <div class="w-100">
            <div v-if="activities && activities.length > 0">
              <ViewActivity
                canTracking
                :activity="activity"
                :key="activity.identity"
                @update="refreshActivity(activity.identity)"
                class="view-activity-item bg-white mb-3"
                v-for="activity in activities" />
              <client-only>
                <infinite-loading @infinite="infiniteHandler">
                  <template slot="spinner">
                    <ViewActivityLoading />
                  </template>
                </infinite-loading>
              </client-only>
            </div>
            <div v-else-if="activities">
              <noData
                v-if="!$userCheck.isSame()"
                :description="`${user.user_info.name.nick} 目前還沒有發布任何動態，去逛逛其他地方或關注他吧！`"
                title="此用戶目前沒有發布動態" />
              <noData
                v-else
                :description="`嗨！${user.user_info.name.nick}，別忘了發佈動態跟大家說說你的投資近況`"
                title="你還沒有發佈任何動態" />
            </div>
            <div v-else class="mb-3">
              <ViewActivityLoading />
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="4" v-if="(articles && articles.length) || (channels && channels.length)">
        <!-- if desktop leave mt-3 spacing -->
        <b-col class="d-none d-sm-none d-md-block d-lg-block d-xl-block mt-3" cols="12" />

        <ArticleNewsList :articles="articles" class="item-list mb-4" v-if="articles && articles.length" />
        <div v-else-if="articles" v-show="false" />
        <ArticleNewsListLoading class="mb-4" v-else />

        <Block class="block-leaderboard" v-if="channelList && channelList.length">
          <div class="mt-2 item-list">
            <div :key="channel.identity" class="item mb-md-4" v-for="channel in channelList">
              <ChannelCardService mobileStyle :channel="channel" />
            </div>
          </div>
        </Block>
        <div v-else-if="channelList" v-show="false" />
        <Block class="block-leaderboard" v-else>
          <div class="mt-2 item-list">
            <div class="item mb-md-4">
              <ChannelCardServiceLoading mobileStyle />
            </div>
          </div>
        </Block>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import ActivityFroalaEditor from '~/components/forms/ActivityFroalaEditor'
  import ArticleNewsList from '~/components/cards/ArticleNewsList'
  import ArticleNewsListLoading from '~/components/cards/ArticleNewsListLoading'
  import Block from '~/components/templates/BaseBlock'
  import ChannelCardService from '~/components/cards/ChannelCardService'
  import ChannelCardServiceLoading from '~/components/cards/ChannelCardServiceLoading'
  import followExpertButNotLoginModal from '~/components/modal/followExpertButNotLoginModal'
  import noData from '~/components/views/exception/noData'
  import PageBar from '~/components/regions/expert/PageBar'
  import ViewActivity from '~/components/views/ViewActivity'
  import ViewActivityLoading from '~/components/views/ViewActivityLoading'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      ActivityFroalaEditor,
      PageBar,
      ViewActivity,
      Block,
      ChannelCardService,
      ArticleNewsList,
      noData,
      ArticleNewsListLoading,
      ViewActivityLoading,
      ChannelCardServiceLoading,
      followExpertButNotLoginModal,
    },
    async asyncData ({ params }) {
      const { uid } = params
      return { uid }
    },
    head () {
      return {
        title: `${this.user.user_info.name.nick}${
          this.user.user_info.belongOrg && _.get(this.user.user_info, 'name.real', '')
            ? `．${this.user.user_info.name.real || ''}`
            : ''
        } - 個人動態`,
        meta: [
          {
            hid:     'title',
            name:    'title',
            content: `${this.user.user_info.name.nick}${
              this.user.user_info.belongOrg && _.get(this.user.user_info, 'name.real', '')
                ? `．${this.user.user_info.name.real || ''}`
                : ''
            } - 個人動態${process.env.SEO.titleSplit}`,
          },
          {
            hid:      'og:title',
            property: 'og:title',
            content:  `${this.user.user_info.name.nick}${
              this.user.user_info.belongOrg && _.get(this.user.user_info, 'name.real', '')
                ? `．${this.user.user_info.name.real || ''}`
                : ''
            } - 個人動態${process.env.SEO.titleSplit}`,
          },
          {
            hid:     'description',
            name:    'description',
            content: this.user.user_info.description
              ? this.user.user_info.description
                .replace(/<br\s*\/?>/gi, ' ')
                .replace(/<\/p>/gi, ' ')
                .replace(/(<([^>]+)>)/gi, '')
                .substr(0, 200)
              : '',
          },
          {
            hid:      'og:description',
            property: 'og:description',
            content:  this.user.user_info.description
              ? this.user.user_info.description
                .replace(/<br\s*\/?>/gi, ' ')
                .replace(/<\/p>/gi, ' ')
                .replace(/(<([^>]+)>)/gi, '')
                .substr(0, 200)
              : '',
          },
        ],
      }
    },
    middleware ({ route, params, redirect }) {
      if (!params || (route.name.match(/^u-uid/) && !params.uid) || (route.name.match(/^o-oas/) && !params.oas)) {
        redirect({ name: 'index' })
      }
    },
    data () {
      return {
        channels:        null,
        content:         '',
        articles:        null,
        activities:      null,
        activitiesPage:  1,
        activitiesLimit: 10,
      }
    },
    computed: {
      ...mapGetters(['user']),
      userCanPublish () {
        return (
          this.$userCheck.isSame()
          || this.$userCheck.isAdmin()
          || this.$userCheck.haveOwnRules(['admin', 'contentFreeEditor', 'contentPaidEditor', 'contentAdmin'])
        )
      },
      userCanSetChannels () {
        return (
          this.$userCheck.isSame()
          || this.$userCheck.isAdmin()
          || this.$userCheck.haveOwnRules(['admin', 'contentPaidEditor', 'contentAdmin'])
        )
      },
      userCanOnlySetChannels () {
        return (
          !this.$userCheck.isSame()
          && !this.$userCheck.isAdmin()
          && !this.$userCheck.haveOwnRules(['admin', 'contentFreeEditor', 'contentAdmin'])
          && this.$userCheck.haveOwnRules(['contentPaidEditor'])
        )
      },
      channelList () {
        if (!this.channels) return null
        return this.channels.filter(channel => !channel.hidden.list)
      },
    },
    methods: {
      ...mapActions('api/activity', ['getActivitiesByAccount']),
      ...mapActions('api/channel', ['getChannels']),
      ...mapActions('api/article', ['getArticlesByAccount']),
      infiniteHandler ($state) {
        this.getActivitiesByAccount({
          account: [this.user.user_info.identity],
          limit:   this.activitiesLimit,
          page:    this.activitiesPage,
        }).then(activities => {
          if (activities.error) {
            if ($state) $state.complete()
            return
          }

          if (!this.activities) this.activities = []
          if (activities.data.length && (this.uid !== '14302' && this.uid !== 14302)) {
            this.activitiesPage += 1
            this.activities.push(...activities.data)
            if ($state) $state.loaded()
          } else {
            if ($state) $state.loaded()
            if ($state) $state.complete()
          }
          if (this.uid === 14302 || this.uid === '14302') {
            this.articles = []
            this.activities = []
            this.channel = []
          }
        })
      },
      refreshActivity (activityIdentity) {
        if (activityIdentity && (this.uid !== '14302' && this.uid !== 14302)) {
          this.activities = this.activities.filter(activity => activity.identity !== activityIdentity)
        } else {
          this.activitiesPage = 1
          this.activities = null
          this.infiniteHandler()
        }
      },
    },
    mounted () {
      this.infiniteHandler()
      const channelsOption = {
        account: [this.user.user_info.identity],
      }
      this.getChannels(channelsOption).then(data => {
        this.channels = !data.error && data.data.length > 0 ? data.data : []
      })

      const articlesOption = {
        account:  [this.user.user_info.identity],
        channels: 'all',
        limit:    4,
        fields:   ['identity', 'title', 'published'],
        sort:     { published: -1 },
      }
      this.getArticlesByAccount(articlesOption).then(data => {
        this.articles = _.get(data, 'data.length', 0) > 0 ? data.data : []
        if (this.uid === '14302' || this.uid === 14302) {
          this.articles = []
        }
      })
    },
  }
</script>

<style lang="scss" scoped>
@include media-breakpoint-down(sm) {
  .activity-post-wrap {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.frame-post {
  background: white;
}

.title-frame {
  font-size: $st_font-size-base;
  font-weight: $st_font-weight-medium;
  line-height: 1.5;
  letter-spacing: $st_font_letter-spacing;
  background: rgba(240, 241, 243, 0.3);
}

.bg-white {
  background: white;
}

.view-activity-item {
  border-radius: 2px;
  border: solid 2px $st_color_gray_lighter;
}
</style>
