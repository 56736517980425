<template>
  <div>
    <BaseTabBar1 :curIndex="curIndex" :items="items" />
  </div>
</template>

<script>
  import BaseTabBar1 from '~/components/templates/BaseTabBar1'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      BaseTabBar1,
    },
    props: {
      curIndex: {
        type:    Number,
        default: () => -1,
      },
    },
    computed: {
      ...mapGetters(['user']),
      isSitca () {
        return _.get(this.user, 'user_info.belongOrgInfo.organization.kind', false) === 'sitca'
      },
      items () {
        const serviceTitle = (_.get(this.user, 'user_info.organization.kind', '') === 'sitca' || this.isSitca) ? '投顧服務' : '組織介紹'
        return [
          {
            title: this.$userCheck.is('organization') ? '動態消息' : '個人動態',
            to:    this.$userRoute({
              name: 'u-uid',
            }),
            show: true,
          },
          {
            title: this.$userCheck.is('organization') ? '財經專欄' : '部落格',
            to:    this.$userRoute({
              name: 'u-uid-articles',
            }),
            show: true,
          },
          {
            title: (this.$userCheck.is('organization') || this.isSitca) ? serviceTitle : '達人服務',
            to:    this.$userRoute({
              name: 'u-uid-service',
            }),
            show: this.$userCheck.are(['service', 'organization']),
          },
          {
            title: '訂閱專區',
            to:    this.$userRoute({
              name: 'u-uid-service-articles',
            }),
            show: this.$userCheck.is('service'),
          },
        ]
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>
