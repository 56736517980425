<template>
  <div class="tab-bar1">
    <b-container class="d-none d-md-flex bg-pale-grey justify-content-around text-center pt-2 pb-2">
      <div
        v-for="(item, index) in showItems" class="d-inline-block tab" :class="{'active': curIndex === index || item.active}"
        :key="index">
        <nuxt-link class="tab-link st_color_main_light" :class="{'active': curIndex === index || item.active}" :to="item.to">
          {{ item.title }}
        </nuxt-link>
      </div>
    </b-container>

    <b-container class="d-flex d-md-none bg-pale-grey justify-content-around text-center bg-purewhite border-bottom pt-2 pb-2">
      <div
        v-for="(item, index) in showMItems" class="d-inline-block tab" :class="{'active': curIndex === index || item.active}"
        :key="index">
        <nuxt-link class="tab-link st_color_main_light" :class="{'active': curIndex === index || item.active}" :to="item.to">
          {{ item.title }}
        </nuxt-link>
      </div>
    </b-container>
  </div>
</template>

<script>
  export default {
    props: {
      curIndex: {
        type:    Number,
        default: () => -1,
      },
      items: {
        type:    Array,
        default: () => [],
      },
      mItems: {
        type:    Array,
        default: () => [],
      },
    },
    computed: {
      showItems () {
        return this.items.filter(item => item.show)
      },
      showMItems () {
        return (this.mItems && this.mItems.length) ? this.mItem.filter(item => item.show) : this.items.filter(item => item.show)
      },
    },
  }
</script>

<style lang="scss">
  .tab-bar1 {
    transition: all 0.2s ease-in-out;

    .bg-pale-grey {
      background: $st_color_gray_lighter;
    }

    > .container {
      @important {
        padding: 0;
      }

      .tab {
        width: 100%;

        .tab-link {
          display: block;
          width: 100%;
          padding: 10px 0;
          border-radius: 2px;
          outline: none;
          border-bottom: 3px rgba(91, 120, 169, 0.1) solid;
          text-align: center;
          font-size: $st_font-size-base;
          letter-spacing: $st_font_letter-spacing;
          color: $st_color_main_light;

          &.active {
            background: white;
            border-bottom: 3px $st_color_main_lighter solid;
            font-weight: $st_font-weight-medium;
            color: $st_color_main_lighter;
          }
        }
      }
    }
  }
</style>
